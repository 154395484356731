// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}ownerInfo/getOwnerParkingList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}ownerInfo/parking/export`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 获取车位地址列表
const getRoomsList = `${API_CONFIG.baseURL}serverCodewordAction!getRooms.action`
// 获取房产信息列表
const getUserRoomsList = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`
// form
// 获取用户列表
const getOwnersList = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`
// 保存车位
const addOwnerParkingUrl = `${API_CONFIG.butlerBaseURL}ownerInfo/addOwnerParking`
// 更新车位
const updateOwnerParkingUrl = `${API_CONFIG.butlerBaseURL}ownerInfo/updateOwnerParking`
// 获取车位详情
const getOwnerParkingInfo = `${API_CONFIG.butlerBaseURL}ownerInfo/getOwnerParkingInfo`
// 车位导入接口
const uploadUrl = `${API_CONFIG.butlerBaseURL}ownerInfo/importOwnerParking`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getRoomsList,
  getUserRoomsList,
  getOwnersList,
  addOwnerParkingUrl,
  updateOwnerParkingUrl,
  getOwnerParkingInfo,
  uploadUrl
}
