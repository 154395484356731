<template>
  <div class="ownerCarList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="导入" permission="add" @click="goPageImport"></v-button>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker label="创建时间" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime" defaultTimeType="month"></v-datepicker>
        <v-select2 label="车位地址" v-model="searchParams.carHouseId" v-bind="carHouseParams"></v-select2>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="手机号" v-model="searchParams.phone"></v-input>
        <v-select2 label="房产信息" v-model="searchParams.houseId" v-bind="houseIdParams"></v-select2>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select label="车位证明" v-model="searchParams.certificateStatus" :options="certificateStatusOps"></v-select>
        <v-select label="共享车位" v-model="searchParams.sharedStatus" :options="sharedStatusOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select label="类型" v-model="searchParams.parkingType" :options="parkingTypeOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getRoomsList,
  getUserRoomsList,
} from './api'
import {
  statusMap,
  certificateStatusMap,
  sharedStatusMap,
  parkingTypeMap
} from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'OwnerCarList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      communityParams,
      certificateStatusOps: [
        {
          text: '全部',
          value: undefined
        },
        {
          text: '未上传',
          value: 0
        },
        {
          text: '待审核',
          value: 1
        },
        {
          text: '已通过',
          value: 2
        },
        {
          text: '未通过',
          value: 3
        }
      ],
      sharedStatusOps: [
        {
          text: '全部',
          value: undefined
        },
        {
          text: '待开启',
          value: 0
        },
        {
          text: '已开启',
          value: 1
        },
        {
          text: '已关闭',
          value: 2
        }
      ],
      statusOps: [
        {
          text: '全部',
          value: undefined
        },
        {
          text: '关闭',
          value: 0
        },
        {
          text: '正常',
          value: 1
        }
      ],
      parkingTypeOps: [
        {
          text: '全部',
          value: undefined
        },
        {
          text: '租',
          value: 1
        },
        {
          text: '售',
          value: 2
        }
      ],
      orgParams: {
        searchUrl: getOrgListURL,
        response: {
          text: 'orgName'
        }
      },
      searchParams: {
        startTime: '',
        endTime: '',
        carHouseId: '',
        userName: '',
        phone: '',
        houseId: '',
        communityId: '',
        certificateStatus: undefined,
        sharedStatus: undefined,
        status: undefined,
        parkingType: undefined
      },
      carHouseParams: {
        searchUrl: getRoomsList,
        request: {
          text: 'roomNum',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'id'
        }
      },
      houseIdParams: {
        searchUrl: getUserRoomsList,
        request: {
          text: 'roomNum',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'id'
        }
      },
      headers: [
        {
          prop: 'carHouseName',
          label: '车位地址'
        },
        {
          prop: 'userName',
          label: '用户姓名'
        },
        {
          prop: 'phone',
          label: '用户手机'
        },
        {
          prop: 'houseName',
          label: '房产信息'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'certificateStatus',
          label: '车位证明',
          formatter (row) {
            return certificateStatusMap[row.certificateStatus]
          }
        },
        {
          prop: 'sharedStatus',
          label: '共享车位',
          formatter (row) {
            return sharedStatusMap[row.sharedStatus]
          }
        },
        {
          prop: 'status',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'parkingType',
          label: '类型',
          formatter (row) {
            return parkingTypeMap[row.parkingType]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    updateStatus (ids, batchURL) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        ids: _ids,
        dataObject: _ids
      }
      _this.$axios.post(batchURL, postData).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    batch (selected) {
      this.updateStatus(selected.ids, batchURL)
    },
    create () {
      this.$router.push({
        name: 'carPlaceForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'carPlaceForm',
        query: {
          id: row.id
        }
      })
    },
    goPageImport () {
      this.$router.push({
        name: 'carPlaceImport'
      })
    }
  }
}
</script>
